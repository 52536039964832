import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { PlusCircle, X, Search, Bell, Edit } from 'lucide-react';
import Checkbox from './ui/Checkbox';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Textarea from './ui/Textarea';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { sendNotificationOnce } from '../utils/notificationService';


const TasksTab = ({ user, onNotificationChange }) => {
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [showNewTaskForm, setShowNewTaskForm] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [newTask, setNewTask] = useState({ 
    title: '', 
    description: '', 
    dueDate: '', 
    dueTime: '',
    projectType: 'interne',
    notified: false
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('active');

  // Fonction pour réorganiser les tâches
  const moveTask = useCallback((fromIndex, toIndex) => {
    const updatedTasks = [...tasks];
    const [movedTask] = updatedTasks.splice(fromIndex, 1);
    updatedTasks.splice(toIndex, 0, movedTask);
    setTasks(updatedTasks);
  }, [tasks]);


  useEffect(() => {
    const unsubscribe = setupTasksListener();
    return () => unsubscribe();
  }, [user]);

  const setupTasksListener = () => {
    const tasksRef = collection(db, 'tasks');
    const q = query(tasksRef, where("userId", "==", user?.id || 'anonymous'));
  
    return onSnapshot(q, async (snapshot) => {
      const fetchedTasks = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      const now = new Date();
      let notificationCount = 0;
  
      for (const task of fetchedTasks) {
        const dueDateTime = new Date(`${task.dueDate}T${task.dueTime}`);
        if (!task.completed && dueDateTime <= now && !task.notified) {
         
  
          updateTaskNotificationStatus(task.id);
          notificationCount++;
        }
      }
  
      onNotificationChange(notificationCount);
      setTasks(fetchedTasks.filter(task => !task.completed));
      setCompletedTasks(fetchedTasks.filter(task => task.completed));
    });
  };
  

  const updateTaskNotificationStatus = async (taskId) => {
    try {
      const taskRef = doc(db, 'tasks', taskId);
      await updateDoc(taskRef, { notified: true });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut de notification:', error);
    }
  };

  const addTask = async () => {
    if (newTask.title.trim() === '') return;

    try {
      const docRef = await addDoc(collection(db, 'tasks'), {
        ...newTask,
        userId: user?.id || 'anonymous',
        completed: false,
        notified: false
      });
      setNewTask({ title: '', description: '', dueDate: '', dueTime: '', projectType: 'interne' });
      setShowNewTaskForm(false);
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la tâche:', error);
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await deleteDoc(doc(db, 'tasks', taskId));
    } catch (error) {
      console.error('Erreur lors de la suppression de la tâche:', error);
    }
  };
  
  const toggleTaskCompletion = async (taskId) => {
    const taskToToggle = tasks.find(t => t.id === taskId) || completedTasks.find(t => t.id === taskId);
    if (taskToToggle) {
      try {
        const taskRef = doc(db, 'tasks', taskId);
        await updateDoc(taskRef, {
          completed: !taskToToggle.completed
        });
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la tâche:', error);
      }
    }
  };

  const editTask = (task) => {
    setEditingTask(task);
    setNewTask(task);
    setShowNewTaskForm(true);
  };

  const updateTask = async () => {
    if (newTask.title.trim() === '') return;

    try {
      const taskRef = doc(db, 'tasks', editingTask.id);
      await updateDoc(taskRef, {
        ...newTask,
        notified: false
      });
      setNewTask({ title: '', description: '', dueDate: '', dueTime: '', projectType: 'interne' });
      setShowNewTaskForm(false);
      setEditingTask(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche:', error);
    }
  };

  const filteredTasks = tasks.filter(task =>
    task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCompletedTasks = completedTasks.filter(task =>
    task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-4 pb-8"> 
      <div className="space-y-4">
        <Button 
          onClick={() => {
            setShowNewTaskForm(!showNewTaskForm);
            setEditingTask(null);
            setNewTask({ title: '', description: '', dueDate: '', dueTime: '', projectType: 'interne' });
          }}
          className="w-full justify-center"
        >
          {showNewTaskForm ? (
            <>
              <X className="mr-2 h-4 w-4" /> Fermer
            </>
          ) : (
            <>
              <PlusCircle className="mr-2 h-4 w-4" /> Ajouter une nouvelle tâche
            </>
          )}
        </Button>

        {!showNewTaskForm && (
          <div className="relative">
            <Input
              placeholder="Rechercher une tâche..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10"
            />
            <Search className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
        )}

        {showNewTaskForm && (
          <Card className="bg-gradient-to-r from-green-50 to-teal-50 shadow-lg">
            <CardContent className="p-6 space-y-4">
              <h3 className="text-2xl font-bold text-center text-gray-800 mb-4">
                {editingTask ? 'Modifier la tâche' : 'Nouvelle tâche'}
              </h3>
              <Input
                placeholder="Titre de la tâche"
                value={newTask.title}
                onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
              />
              <Textarea
                placeholder="Description"
                value={newTask.description}
                onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
              />
              <div className="flex space-x-2">
                <Input
                  type="date"
                  value={newTask.dueDate}
                  onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
                />
                <Input
                  type="time"
                  value={newTask.dueTime}
                  onChange={(e) => setNewTask({ ...newTask, dueTime: e.target.value })}
                />
              </div>
              <select
                value={newTask.projectType}
                onChange={(e) => setNewTask({ ...newTask, projectType: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="interne">Interne</option>
                <option value="externe">Externe</option>
                <option value="autres">Autres</option>
              </select>
              <Button onClick={editingTask ? updateTask : addTask} className="w-full">
                {editingTask ? 'Mettre à jour la tâche' : 'Ajouter la tâche'}
              </Button>
            </CardContent>
          </Card>
        )}

        <div className="flex space-x-2 mb-4">
          <Button 
            onClick={() => setActiveTab('active')}
            variant={activeTab === 'active' ? 'default' : 'outline'}
          >
            Mes tâches
          </Button>
          <Button 
            onClick={() => setActiveTab('completed')}
            variant={activeTab === 'completed' ? 'default' : 'outline'}
          >
            Tâches terminées
          </Button>
        </div>

        {activeTab === 'active' && (
          <>
            <h2 className="text-2xl font-bold">Mes tâches</h2>
        <ul className="space-y-2">
          {tasks.map((task, index) => (
            <TaskItem
              key={task.id}
              task={task}
              index={index}
              moveTask={moveTask}
              deleteTask={deleteTask}
              toggleTaskCompletion={toggleTaskCompletion}
              editTask={editTask}
            />
              ))}
            </ul>
          </>
        )}

        {activeTab === 'completed' && (
          <>
            <h2 className="text-2xl font-bold">Tâches terminées</h2>
            <ul className="space-y-2">
              {filteredCompletedTasks.map((task, index) => (
                <TaskItem
                  key={task.id}
                  task={task}
                  index={index}
                  deleteTask={deleteTask}
                  toggleTaskCompletion={toggleTaskCompletion}
                  editTask={editTask}
                />
              ))}
            </ul>
          </>
        )}
      </div>
      </div>
    </DndProvider>
  );
};

const TaskItem = ({ task, index, moveTask, deleteTask, toggleTaskCompletion, editTask }) => {
  const isOverdue = new Date(`${task.dueDate}T${task.dueTime}`) <= new Date() && !task.completed;

  // Utilisation de useDrag pour rendre l'élément draggable
  const [{ isDragging }, drag] = useDrag({
    type: 'TASK',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Utilisation de useDrop pour permettre la réorganisation
  const [, drop] = useDrop({
    accept: 'TASK',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveTask(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <li
      ref={(node) => drag(drop(node))}
      className={`p-2 border rounded ${task.completed ? 'bg-orange-100' : isOverdue ? 'bg-red-800 text-white' : 'bg-white'}`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Checkbox
            checked={task.completed}
            onCheckedChange={() => toggleTaskCompletion(task.id)}
          />
          <h3 className={`font-semibold ml-2 ${task.completed ? 'line-through text-orange-500' : ''}`}>
            {task.title}
          </h3>
        </div>
        <div className="flex items-center">
          <Button onClick={() => editTask(task)} variant="ghost" size="sm" className="mr-2">
            <Edit className="h-4 w-4" />
          </Button>
          <Button onClick={() => deleteTask(task.id)} variant="ghost" size="sm">
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <p className={`ml-6 text-gray-600 ${task.completed ? 'line-through' : ''}`}>
        Date due : {task.dueDate} à {task.dueTime}
      </p>
      {task.description && (
        <p className={`ml-6 text-gray-600 ${task.completed ? 'line-through' : ''}`}>
          {task.description}
        </p>
      )}
      <p className={`ml-6 text-gray-600 ${task.completed ? 'line-through' : ''}`}>
        Type : {task.projectType}
      </p>
    </li>
  );
};


export default TasksTab;