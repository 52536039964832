import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDOTpOHX3QHaIpi_L8vLyp0AZGXZU1FPeg",
    authDomain: "gestion-projet-hery.firebaseapp.com",
    projectId: "gestion-projet-hery",
    storageBucket: "gestion-projet-hery.appspot.com",
    messagingSenderId: "233399197845",
    appId: "1:233399197845:web:5c00f1e29a3e47b532fb9c",
    measurementId: "G-HRNLD216ZG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);  // Use Firestore instead of Realtime Database
const auth = getAuth(app);
const storage = getStorage(app);


export { auth, db, storage };
